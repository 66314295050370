export default {
    zh:{
        cxGgh:{
            queryParam:{
                name:'车型公告号',
                name_placeholder:'请输入车型公告号',
                cxId:'车型',
                cxId_placeholder:'请选择车型',
            },
            columns:{
                F_NAME:'车型公告号',
                F_CX_ID:'车型',
            },
            form:{
                cxId:'车型',
                cxId_placeholder:'请选择车型',
                cxId_rule: '请选择车型',
                name:'车型公告号',
                name_placeholder:'请输入车型公告号',
                name_rule: '请输入车型公告号',
            },
            CxGghDetail:{
                F_DESC:'配置描述',
                F_DESC_PLACEHOLDER:'请输入配置描述',
                F_COUNTRY_ID:'上市国家',
                F_COUNTRY_ID_PLACEHOLDER:'请输入上市国家',
            },
        }
    },
    en:{
        cxGgh:{
            queryParam:{
                name:'车型公告号',
                name_placeholder:'请输入车型公告号',
                cxId:'车型',
                cxId_placeholder:'请选择车型',
            },
            columns:{
                F_NAME:'车型公告号',
                F_CX_ID:'车型',
            },
            form:{
                cxId:'车型',
                cxId_placeholder:'请选择车型',
                cxId_rule: '请选择车型',
                name:'车型公告号',
                name_placeholder:'请输入车型公告号',
                name_rule: '请输入车型公告号',
            },
            CxGghDetail:{
                F_DESC:'配置描述',
                F_DESC_PLACEHOLDER:'请输入配置描述',
                F_COUNTRY_ID:'上市国家',
                F_COUNTRY_ID_PLACEHOLDER:'请输入上市国家',
            },
        }
    }
}