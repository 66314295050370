import BaseBean from "@/utils/BaseBean";

export interface ICxGghListDataObj {
    utilInst:CxGghListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class CxGghListUtil extends BaseBean{
    public dataObj:ICxGghListDataObj

    constructor(proxy:any,dataObj:ICxGghListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        this.dataObj.otherParams.cxData=await this.utils.OrderProviderApi.getAllCx({});
        this.dataObj.otherParams.brandData=await this.utils.OrderProviderApi.getAllBrand({});
    }
}