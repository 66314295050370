import CxGghCard from './CxGghCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import CxGghListUtil,{ICxGghListDataObj} from './cxGghListUtil';
import language from './cxGghLanguage'
export default defineComponent ({
    name: 'CxGghList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:ICxGghListDataObj=reactive<ICxGghListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                brandData:[],
                cxData:[],
                queryParam: {},
                modelComp: CxGghCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/cxGgh/pageData')
            },
            otherParams:{},
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CxGghListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'brandId'==params.comboId){
                    return dataObj.otherParams.brandData
                }
                if(params && 'cxId'==params.comboId){
                    return dataObj.otherParams.cxData
                }
            }
        })
        const selectOnChange=async (newValue:string,selectId:string)=>{
            if('brandId'==selectId){
                if(newValue){
                    dataObj.otherParams.cxData=await utils.OrderProviderApi.getAllCx({params:{brandId:newValue}});
                }else{
                    dataObj.otherParams.cxData=await utils.OrderProviderApi.getAllCx({});
                }
                dataObj.pageList.queryParam.cxId='';
            }
        }
        const formatPageInfo=(options:any)=>{
            return options;
        }

        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,formatPageInfo
        }
    }
});